import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AvatarComponent as AvatarDesignC } from "./avatar/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/avatar.component";
import { AvatarControlsComponent } from "./avatar-controls/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/avatar-controls.component";
import { AvatarConversationBoardComponent } from "./avatar-conversation-board/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/avatar-conversation-board.component";
import { DragDropComponent as DragDropDesignC } from "./avatar-drag-drop/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/avatar-drag-drop.component";
import { FeedbackComponent as FeedbackDesignC } from "./avatar-feedback/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/avatar-feedback.component";
import { AvatarHeadingComponent } from "./avatar-heading/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/avatar-heading.component";
import { AvatarInitComponent as AvatarInitDesignC } from "./avatar-init/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/avatar-init.component";
import { ShortcutsComponent as ShortcutDesignC } from "./avatar-shortcuts/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/avatar-shortcuts.component";
import { ContentModalComponent as ContentModalDesignC } from "./content-modal/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/content-modal.component";
import { LandingPageComponent as LandingPageDesignC } from "./landing-page/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/landing-page.component";
import { LanguagesComponent as LanguagesDesignC } from "./languages/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/languages.component";
import { MessageComponent as MessageDesignC } from "./message/design/3d41f6bc-ad1e-47fe-822d-c0653c22a3f8/message.component";
import { CloseSvgComponent } from './avatar-feedback/svg/close';
import { AttachmentSvgComponent } from './avatar-controls/svg/attachment';
import { MicrophoneSvgComponent } from './avatar-controls/svg/microphone';
import { ThumbUpSvgComponent as ThumbUpSvgDesignC } from "./message/svg/thumbs-up";
import { ThumbDownSvgComponent as ThumbDownSvgDesignC } from "./message/svg/thumbs-down";
import { SafePipe } from "../pipes/safe.pipe";

@NgModule({
    declarations: [
        AvatarDesignC,
        AvatarControlsComponent,
        AvatarConversationBoardComponent,
        DragDropDesignC,
        FeedbackDesignC,
        AvatarHeadingComponent,
        AvatarInitDesignC,
        ContentModalDesignC,
        LandingPageDesignC,
        LanguagesDesignC,
        ShortcutDesignC,
        MessageDesignC,
        ThumbUpSvgDesignC,
        ThumbDownSvgDesignC,
        CloseSvgComponent,
        AttachmentSvgComponent,
        MicrophoneSvgComponent,
        SafePipe,
    ],
    exports: [
        AvatarDesignC,
        AvatarControlsComponent,
        AvatarConversationBoardComponent,
        DragDropDesignC,
        FeedbackDesignC,
        AvatarHeadingComponent,
        AvatarInitDesignC,
        ContentModalDesignC,
        DragDropDesignC,
        FeedbackDesignC,
        LandingPageDesignC,
        LanguagesDesignC,
        ShortcutDesignC,
        MessageDesignC,
        ThumbUpSvgDesignC,
        ThumbDownSvgDesignC,
        CloseSvgComponent,
        AttachmentSvgComponent,
        MicrophoneSvgComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgOptimizedImage
    ]
})
export class ComponentModule {}
